<template>
  <div>
    <el-container style="height: 100vh;">
      <el-header>
        <Header></Header>
      </el-header>
      <el-container style="background: #efefef;">
        <div class="bffffff">
          <div class=" direction align">
            <i class="iconfont icon-shangjiazhucechenggongtishi1"></i>
            <h4 class="h">恭喜您成功注册商家账号！</h4>
            <p class="p">请联系十二仓运营，开通专属商家后台权限。</p>
            <img
              height="160"
              width="160"
              src="https://12cang.oss-cn-hangzhou.aliyuncs.com/assets/login/jm-qrcode.png"
              alt=""
            />
            <div>
              <span class="mr12">联系人：金猫</span>
              <span>手机号：15669039005</span>
            </div>
          </div>
        </div>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from '@/pages/layout/Header';
export default {
  components: { Header },
};
</script>

<style lang="less" scoped>
.bffffff {
  margin: 20px;
  width: 100%;
}
.iconfont {
  font-size: 54px;
  color: #52c41a;
  margin-top: 100px;
  margin-bottom: 20px;
}
.h {
  font-size: 26px;
  margin-bottom: 8px;
}
.p {
  font-size: 16px;
}
img {
  margin-top: 32px;
  margin-bottom: 16px;
}
</style>
